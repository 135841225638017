import React from 'react'
import { Link } from 'gatsby'
import { API_URL } from '../../../service/config'

const Specialities = ({ data, title }) => {
    const d = data.filter(el => {
        return el._show === true;
     });

    if(d.length > 0){
        return (
            <>
                <h2 className="text-center text-uppercase my-4">{title}</h2>
                <ul className="row">
                    {
                        d.map((item, index) => {
                            return (
                                <li class="col-md-4 col-lg-3" key={index}>
                                        <Link to={item?.path} class="ddlink"> 
                                            <span class="icon_wrap">
                                                <img src={(item.icon && item.icon.url) ? `${item.icon.url}` : 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNi44MTciIGhlaWdodD0iNDguNDEzIiB2aWV3Qm94PSIwIDAgMzYuODE3IDQ4LjQxMyI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6Izc4YTRkODtmaWxsLXJ1bGU6ZXZlbm9kZDt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkxOC4yMDcgLTEzNTUuNTEyKSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMjIuMTYzLDMzOC41NTJjLTQuNTY4LjAyNi02LjcxMy0yLjc1OC04LjUyNS01Ljk4NS0uMTQyLS4yNTItLjMtLjQ5NC0uNDU3LS43NC0uNjIzLTEtMS41LTEuNjYzLTIuNjQ5LTEuMTA4YTIuMTI4LDIuMTI4LDAsMCwwLS45NzEsMi44NjMsMTYuMjkyLDE2LjI5MiwwLDAsMCwxLjAxLDIuN2MuNiwxLjA5Mi4xNjEsMS42OS0uOCwyYTI4Ljg0NiwyOC44NDYsMCwwLDEtMy4yMzMuNzJjLS40NTYuMDktLjkyNi4xLTEuMzkxLjE1LS4wMzkuMTM3LS4wNzcuMjc1LS4xMTUuNDExYTE3LjczLDE3LjczLDAsMCwwLDMuMzM4LDEuMyw0Mi45MjYsNDIuOTI2LDAsMCwwLDYuMDI1LjYxNGMyLjY0Mi4xLDQuMjIuOTUzLDQuNTUyLDIuNzE0LjM4MSwyLjAxNC0uNzgzLDMuNy0zLjM5Myw0LjUwOS00LjE5MiwxLjMtOC4xNC4yNC0xMS44NzUtMS42NzEtNC41LTIuMy02LjcxMS01Ljk2My01Ljk5LTEwLjM4NGEzMC4yMzIsMzAuMjMyLDAsMCwxLDIuMzY2LTcuMTA3LDUxLjExOSw1MS4xMTksMCwwLDEsMy42NTQtNi43MzIsOC4wNTcsOC4wNTcsMCwwLDEsNy41NDgtMy45NzZjMy42Mi4xMjMsNy4yNS4wNzUsMTAuODc0LDBhNy4xLDcuMSwwLDAsMSw2LjU2NSwzLjI2YzEuODIsMi44ODksMy40NDYsNS45MTIsNC45ODMsOC45NjVhNy41LDcuNSwwLDAsMSwuMzIxLDUuNTIzYy0xLjEzNSw0LTYuMjg5LDguNDA2LTEwLjA1NSw4LjU4NC0xLjU4NS4wNzUtMy4wMjgtLjE0Mi0zLjc3My0xLjc4My0uNjk1LTEuNTI5LjA3OC0yLjY5NCwxLjAzMi0zLjc5MS4yMTYtLjI0OC40NDUtLjQ4Ny45NTUtMS4wNDRabS0uMTcxLTEyLjAzOGE1LjIyNCw1LjIyNCwwLDAsMC01LjI4Myw1LjIsNS4yNzEsNS4yNzEsMCwxLDAsMTAuNTQxLjE1M0E1LjIsNS4yLDAsMCwwLDEyMS45OTMsMzI2LjUxNFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDgyMC42NDggMTA1NC42NDQpIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMjYuNjM2LDMwNC4yMTZhNy45NDMsNy45NDMsMCwwLDEtNy44MzEsOC4wMjMsOC4wNTcsOC4wNTcsMCwxLDEtLjI0NC0xNi4xMTJBOCw4LDAsMCwxLDEyNi42MzYsMzA0LjIxNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDgxNy45MzYgMTA1OS4zODUpIi8+PC9nPjwvc3ZnPg=='} alt={item?.name}/>
                                            </span> 
                                            <span class="link_text">{item?.name}</span> 
                                        </Link>
                                </li>
                            )
                        })
                            
                    }
                </ul>
            </>
        );
    }else{
        return(
            <>
                <h2 className="text-center text-uppercase my-4">{title}</h2>
                <p className="text-center">Currently there is no data.</p>
            </>
        )
    }

}

export default Specialities